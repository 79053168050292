import ListGroup from "react-bootstrap/ListGroup";
import TabService from "./TabService";
import Users from "../users/list/UsersContainer";
import LoginService from "../auth/LoginService";
import Clients from "../clients/list/ClientsContainer";
import NewClientContainer from "../clients/new/NewClientContainer";
import EmailClientsContainer from "../clients/email-clients/EmailClientsContainer";

export default function Sidebar(props) {
  return (
    <ListGroup>
      <ListGroup.Item
        action
        onClick={() => TabService.open(<NewClientContainer />, "New Client")}
      >
        New Client
      </ListGroup.Item>
      <ListGroup.Item
        action
        onClick={() => TabService.open(<Clients />, "Clients")}
      >
        Clients
      </ListGroup.Item>
      <ListGroup.Item
        action
        onClick={() =>
          TabService.open(<EmailClientsContainer />, "Email Clients")
        }
      >
        Email Clients
      </ListGroup.Item>
      <ListGroup.Item
        action
        onClick={() => TabService.open(<Users />, "Users")}
      >
        Users
      </ListGroup.Item>
      <ListGroup.Item action onClick={() => LoginService.logout()}>
        Logout
      </ListGroup.Item>
    </ListGroup>
  );
}
