import React from "react";
import Table from "react-bootstrap/Table";

export default class Users extends React.Component {
  static displayName = "Users";

  render() {
    return (
      <Table striped hover size="sm">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Last Login</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {this.props.users.map((u) => {
            return (
              <tr key={u.id}>
                <td>{u.name}</td>
                <td>{u.email}</td>
                <td>{u.role}</td>
                <td>{u.last_login}</td>
                <td></td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }
}
