import React from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import LoginService from "./LoginService";
import staffconnect_logo from "../../assets/staffconnect.svg";
import axios from "axios";

export default class Login extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      errors: [],
      message: "",
      disableLogin: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.login = this.login.bind(this);
    this.wrongLogin = this.wrongLogin.bind(this);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  login() {
    this.setState({
      disableLogin: true,
    });

    axios
      .post(LoginService.getLoginUrl(), {
        email: this.state.email,
        password: this.state.password,
      })
      .then((res) => {
        if (res.data.success) {
          LoginService.setUser(res.data.user);
          this.setState({
            disableLogin: false,
          });
        } else {
          this.wrongLogin(res.data);
        }
      })
      .catch((err) => {
        this.wrongLogin(err.response.data);
      });
  }

  wrongLogin(error) {
    this.setState({
      errors: error.errors ? error.errors : [],
      message: error.message,
      disableLogin: false,
    });
  }

  render() {
    return (
      <Container>
        <Row className="mt-4">
          <Col md={{ span: 4, offset: 4 }}>
            <Row className="justify-content-md-center m-4">
              <img
                src={staffconnect_logo}
                height="150"
                alt="StaffConnect Logo"
              />
            </Row>
            <Form>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  onChange={this.handleChange}
                  isInvalid={this.state.errors.email?.length}
                />
                {this.state.errors.email?.length > 0 && (
                  <Form.Control.Feedback type="invalid">
                    {this.state.errors.email.map((e) => e)}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  name="password"
                  onChange={this.handleChange}
                  isInvalid={this.state.errors.password?.length}
                />
                {this.state.errors.password?.length > 0 && (
                  <Form.Control.Feedback type="invalid">
                    {this.state.errors.password.map((e) => e)}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              {this.state.message && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {this.state.message}
                </Form.Control.Feedback>
              )}

              <Button
                variant="primary"
                onClick={() => this.login()}
                className="mt-3"
                disabled={this.state.disableLogin}
              >
                Login
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}
