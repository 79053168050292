import React from "react";
import { Table, Button, Row, Col, Badge } from "react-bootstrap";
import EditPlanContainer from "../edit-plan/EditPlanContainer";
import TabService from "../../main/TabService";
import NotesContainer from "../notes/NotesContainer";

export default class ClientView extends React.Component {
  static displayName = "ClientView";

  render() {
    return (
      <div>
        <Row>
          <Col>
            <span className="m-3">
              {this.props.client}
              {this.props.customer.plan === "free" && (
                <Badge className="bg-primary mx-4">Free Trial</Badge>
              )}
            </span>
          </Col>
          <Col>
            <Button
              className="float-end mt-3"
              variant="outline-primary"
              onClick={() =>
                TabService.open(
                  <NotesContainer client_id={this.props.id} />,
                  this.props.client + " notes"
                )
              }
            >
              View Notes
            </Button>
          </Col>
        </Row>
        <hr />
        <ul>
          {this.props.domains.map((d) => {
            return (
              <li>
                <a href={"https://" + d.fqdn} target="_blank" rel="noreferrer">
                  {d.fqdn}
                </a>
              </li>
            );
          })}
        </ul>
        {this.props.loaded && (
          <React.Fragment>
            <h4>Users</h4>
            <Table striped size="sm">
              <thead>
                <tr>
                  <th>Admins</th>
                  <th>Staff</th>
                  <th>Registrants</th>
                  <th>Inactive</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{this.props.numAdmins}</td>
                  <td>{this.props.numStaff}</td>
                  <td>{this.props.numRegistrants}</td>
                  <td>{this.props.numInactive}</td>
                  <td>{this.props.numUsers}</td>
                </tr>
              </tbody>
            </Table>

            <h4>Shifts</h4>
            <Table striped size="sm">
              <thead>
                <tr>
                  <th>{this.props.lastMonth}</th>
                  <th>{this.props.thisMonth}</th>
                  <th>{this.props.nextMonth}</th>
                  <th>{this.props.nextNextMonth}</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{this.props.numLastMonth}</td>
                  <td>{this.props.numThisMonth}</td>
                  <td>{this.props.numNextMonth}</td>
                  <td>{this.props.numNextNextMonth}</td>
                  <td>{this.props.numShifts}</td>
                </tr>
              </tbody>
            </Table>

            <h4>Owners</h4>
            <Table striped size="sm">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Last Login</th>
                </tr>
              </thead>
              <tbody>
                {this.props.owners.map((o) => {
                  return (
                    <tr key={o.email}>
                      <td>
                        {o.fname} {o.lname}
                      </td>
                      <td>{o.email}</td>
                      <td>{o.last_login_ago}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            <h4>Admins</h4>
            <Table striped size="sm">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Last Login</th>
                </tr>
              </thead>
              <tbody>
                {this.props.admins.map((a) => {
                  return (
                    <tr key={a.email}>
                      <td>
                        {a.fname} {a.lname}
                      </td>
                      <td>{a.email}</td>
                      <td>{a.last_login_ago}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            <h4>
              Plan: {this.props.plan}
              <Button
                className="btn btn-primary m-2"
                size="sm"
                onClick={() =>
                  TabService.open(
                    <EditPlanContainer
                      customer_id={this.props.customer.id}
                      updatePlan={this.props.updatePlan}
                    />,
                    "Edit Plan: " + this.props.client
                  )
                }
              >
                Edit
              </Button>
            </h4>

            <h4>Bills</h4>
            {this.props.bills.length > 0 && (
              <Table striped size="sm">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Currency</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Link</th>
                    <th>PDF</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.bills.map((b) => {
                    return (
                      <tr key={b.id}>
                        <td>{b.date.date.substr(0, 10)}</td>
                        <td>{b.currency}</td>
                        <td>{b.total}</td>
                        <td>{b.status}</td>
                        <td>
                          <a
                            href={b.stripe_hosted_url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            View / Pay
                          </a>
                        </td>
                        <td>
                          <a
                            href={b.stripe_pdf_url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            PDF
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
            {this.props.bills.length === 0 && <p>No bills yet.</p>}

            <h4>Freeze System</h4>
            <label className="switch">
              <input
                type="checkbox"
                onChange={() => this.props.freeze(!this.props.freezeSystem)}
                defaultChecked={this.props.freezeSystem}
              />
              <span className="slider round"></span>
            </label>
          </React.Fragment>
        )}
      </div>
    );
  }
}
