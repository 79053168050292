import Config from "../../config/config";
import { Subject } from "rxjs";

const LoginService = {
  onLogin: new Subject(),
  onLogout: new Subject(),

  logout: function () {
    window.localStorage.removeItem("user");
    this.onLogout.next();
  },

  isLoggedIn: function () {
    return LoginService.getAccessToken() ? true : false;
  },

  setUser: function (user) {
    window.localStorage.setItem("user", JSON.stringify(user));
    this.onLogin.next();
  },

  getUser: function () {
    let user = null;

    try {
      user = JSON.parse(window.localStorage.getItem("user"));
    } catch (e) {}

    return user;
  },

  getAccessToken: function () {
    return LoginService.getUser() ? LoginService.getUser().access_token : false;
  },

  getLoginUrl: function () {
    return Config.api + "login";
  },
};

export default LoginService;
