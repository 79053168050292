import React from "react";
import ClientService from "../ClientService";
import AlertService from "../../alert/AlertService";
import EmailClients from "./EmailClients";

export default class EmailClientsContainer extends React.Component {
  static displayName = "EmailClientsContainer";

  constructor(props) {
    super(props);

    this.state = {
      subject: "",
      contents: "",
      preview: true,
      disableSend: false,
      errors: {},
      recipients: []
    };

    this.clientService = new ClientService();

    this.handleChange = this.handleChange.bind(this);
    this.send = this.send.bind(this);
  }

  handleChange(e, data = null) {
    if (data) this.setState({ [e]: data });
    else
      this.setState({
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      });
  }

  send() {
    this.setState({ disableSend: true });
    this.clientService.emailClients({
      subject: this.state.subject,
      contents: this.state.contents,
      preview: this.state.preview,
    }).then(res => {
      AlertService.show(res.data.message);
      this.setState({
        errors: [],
        disableSend: false,
        recipients: res.data.recipients
      });
    }).catch(err => {
      if (err.response.data.errors)
        this.setState({
          errors: err.response.data.errors,
          disableSend: false,
        });

      if (err.response.data.message)
        AlertService.show(err.response.data.message, "red");
    });
  }

  render() {
    return (
      <EmailClients
        {...this.state}
        send={this.send}
        handleChange={this.handleChange}
      />
    );
  }
}
