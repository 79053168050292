import React from "react";
import EditPlan from "./EditPlan";
import ClientService from "../ClientService";
import TabService from "../../main/TabService";
import AlertService from "../../alert/AlertService";

export default class EditPlanContainer extends React.Component {
  static displayName = "EditPlanContainer";

  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      plans: [],
      plan: 'free',
      currencies: [],
      bill_period: 'monthly',
      bill_enable: false,
      disableSave: false,
      oldPlan: false
    };

    this.clientService = new ClientService();

    this.save = this.save.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.clientService.getEditPlanData(this.props.customer_id).then((res) => {
      let st = res.data;
      st.plan = st.plan || st.plans[0].id;
      st.currency = st.currency || st.currencies[0];
      if (['user', 'admin', 'unlimited'].includes(st.plan)) {
        st.oldPlan = true;
      }
      this.setState({ ...st });
    });
  }

  handleChange(e) {
    const data = {
      [e.target.name]: e.target.name === 'bill_enable' ? !e.target.checked : e.target.value,
    };

    if (e.target.name === 'plan' || e.target.name === 'bill_period') {
      switch (e.target.name === 'plan' ? e.target.value : this.state.plan) {

        case 'starter':
          data.currency = 'USD';
          data.free_admins = 1;
          data.admin_price = 80;
          data.free_staff = 50;
          data.staff_price = 5;
          data.staff_increments = 50;
          data.app_price = 20;
          if (e.target.name === 'bill_period' ? e.target.value === 'yearly' : this.state.bill_period === 'yearly') {
            data.base_price = 100;
          } else {
            data.base_price = 120;
          }
          data.oldPlan = false;
          break;

        case 'professional':
          data.currency = 'USD';
          data.free_admins = 1;
          data.admin_price = 65;
          data.free_staff = 500;
          data.staff_price = 25;
          data.staff_increments = 500;
          data.app_price = 10;
          if (e.target.name === 'bill_period' ? e.target.value === 'yearly' : this.state.bill_period === 'yearly') {
            data.base_price = 125;
          } else {
            data.base_price = 155;
          }
          data.oldPlan = false;
          break;

        case 'enterprise':
          data.currency = 'USD';
          data.oldPlan = false;
          break;

        default:
          data.oldPlan = true;
      }
    }

    this.setState(data);
  }

  save() {
    let data = {
      plan: this.state.plan,
      unit_price: this.state.unit_price,
      min_total: this.state.min_total,
      currency: this.state.currency,
      free_admins: this.state.free_admins,
      admin_price: this.state.admin_price,
      free_staff: this.state.free_staff,
      staff_price: this.state.staff_price,
      staff_increments: this.state.staff_increments,
      app_price: this.state.app_price,
      base_price: this.state.base_price,
      bill_enable: this.state.bill_enable,
      bill_period: this.state.bill_period,
      bill_start: this.state.bill_start
    };

    if (this.state.plan === 'free') {
      data = { plan: 'free' };
    }

    if (this.state.email) data.email = this.state.email;

    this.setState({
      disableSave: true,
    });

    this.clientService.savePlan(this.props.customer_id, data).then(res => {
      TabService.closeCurrentTab();
      AlertService.show("Plan saved.");
      this.props.updatePlan(res.data.plan.plan);
    }).catch(err => {
      if (err.response.data.errors) {
        this.setState({
          errors: err.response.data.errors,
          disableSave: false,
        });
      } else {
        this.setState({
          disableSave: false,
        });
      }

      if (err.response.data.message)
        AlertService.show(err.response.data.message, "red");
    });
  }

  render() {
    return (
      <EditPlan
        {...this.state}
        save={this.save}
        handleChange={this.handleChange}
        handleCheck={this.handleCheck}
      />
    );
  }
}
