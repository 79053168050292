import React from "react";
import { Table, Form, Button, Row, Col } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./EmailClients.scss";

export default class EmailClients extends React.Component {
  static displayName = "EmailClients";

  render() {
    return (
      <div>
        <Form>
          <Form.Group className="my-3">
            <Form.Control
              type="text"
              placeholder="Subject"
              name="subject"
              defaultValue={this.props.subject}
              onChange={this.props.handleChange}
              isInvalid={this.props.errors.subject?.length}
            />
            {this.props.errors.subject?.length > 0 && (
              <Form.Control.Feedback type="invalid">
                {this.props.errors.subject.map((e) => e)}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group>
            <CKEditor
              editor={ClassicEditor}
              data={this.props.contents}
              onChange={(event, editor) => {
                const data = editor.getData();
                this.props.handleChange("contents", data);
              }}
            />
            {this.props.errors.contents?.length > 0 && (
              <Form.Control.Feedback type="invalid" className="d-block">
                {this.props.errors.contents.map((e) => e)}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Row>
            <Col>
              {" "}
              <Form.Group>
                <Form.Label>Preview</Form.Label>
                <br />
                <label class="switch">
                  <input
                    name="preview"
                    type="checkbox"
                    onChange={this.props.handleChange}
                    defaultChecked={this.props.preview}
                  />
                  <span class="slider round"></span>
                </label>
              </Form.Group>
            </Col>
            <Col>
              {" "}
              <Button
                variant="primary"
                onClick={() => this.props.send()}
                className="mt-3 float-end"
                disabled={this.props.disableSend}
              >
                Send
              </Button>
            </Col>
          </Row>
        </Form>

        {this.props.recipients.length > 0 && (
          <Table className="mt-3">
            <thead>
              <tr>
                <th>Client</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Lvl</th>
              </tr>
            </thead>
            <tbody>
              {this.props.recipients.map((u, i) => {
                return (
                  <tr>
                    <td>
                      {u.customer}
                    </td>
                    <td>
                      {u.fname}
                    </td>
                    <td>
                      {u.lname}
                    </td>
                    <td>
                      {u.email}
                    </td>
                    <td>
                      {u.lvl}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </div>
    );
  }
}
