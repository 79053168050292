import React from "react";
import { Form, Button, Table, OverlayTrigger, Tooltip, Badge } from "react-bootstrap";
import "./Notes.scss";
import ModalService from "../../modal/ModalService";

export default class Notes extends React.Component {
  static displayName = "Notes";

  render() {
    return (
      <>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Type</Form.Label>
            <Form.Control
              as="select"
              name="type"
              value={this.props.type}
              onChange={this.props.handleChange}
              isInvalid={this.props.errors.type?.length}
            >
              <option value="info">Info</option>
              <option value="billing">Billing</option>
            </Form.Control>
            {this.props.errors.type?.length > 0 && (
              <Form.Control.Feedback type="invalid">
                {this.props.errors.type.map((e) => e)}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Note</Form.Label>
            <Form.Control
              as="textarea"
              name="note"
              value={this.props.note}
              onChange={this.props.handleChange}
              isInvalid={this.props.errors.note?.length}
            />
            {this.props.errors.note?.length > 0 && (
              <Form.Control.Feedback type="invalid">
                {this.props.errors.note.map((e) => e)}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Button
            variant="primary"
            onClick={() => this.props.save()}
            disabled={this.props.disableSave}
          >
            Save
          </Button>
        </Form>
        <Table className="notes-table">
          <thead>
            <tr>
              <td>Note</td>
              <td>Admin</td>
              <td>Action</td>
            </tr>
          </thead>
          <tbody>
            {this.props.notes.map((n) => {
              let className = "note-type-" + n.type;
              return (
                <tr className={className}>
                  <td>
                    {n.note}
                    {n.type === "short" && (
                      <Badge className="bg-secondary float-end">Short</Badge>
                    )}
                  </td>
                  <td>
                    {n.creator.name}
                    <br />
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip>{n.created_at}</Tooltip>}
                    >
                      <small>{n.created_ago}</small>
                    </OverlayTrigger>
                  </td>
                  <td>
                    <Button
                      variant="outline-primary"
                      size="sm"
                      className="m-2"
                      onClick={() => this.props.edit(n)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outline-danger"
                      size="sm"
                      onClick={() =>
                        ModalService.show(
                          "Delete note?",
                          ["Really delete note?"],
                          () => {
                            this.props.delete(n);
                          },
                          null,
                          "Delete"
                        )
                      }
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </>
    );
  }
}
