import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "../main/Sidebar";
import Tabs from "./Tabs";
import Alert from "../alert/Alert";
import Modal from "../modal/Modal";
import staffconnect_logo from "../../assets/staffconnect.svg";
import LoginService from "../auth/LoginService";
import Login from "../auth/Login";
import axios from "axios";

export default class Main extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: LoginService.isLoggedIn(),
    };

    this.onLoginSubscription = null;
    this.onLogoutSubscription = null;
  }

  componentDidMount() {
    this.onLoginSubscription = LoginService.onLogin.subscribe(() => {
      this.setState({
        loggedIn: true,
      });
    });

    this.onLogoutSubscription = LoginService.onLogout.subscribe(() => {
      this.setState({
        loggedIn: false,
      });
    });

    this.setAxiosInterceptors();
  }

  componentWillUnmount() {
    this.onLoginSubscription.unsubscribe();
    this.onLogoutSubscription.unsubscribe();
  }

  setAxiosInterceptors() {
    axios.interceptors.request.use(
      function (config) {
        config.headers.Authorization = LoginService.getAccessToken();
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response !== undefined && error.response.status === 401) {
          LoginService.logout();
        }
        return Promise.reject(error);
      }
    );
  }

  render() {
    return this.state.loggedIn ? (
      <Container className="mb-4" fluid>
        <Row>
          <Col className="py-2 px-4">
            <img
              src={staffconnect_logo}
              height="50"
              className="mx-2"
              alt="StaffConnect Logo"
            />
            StaffConnect 4
          </Col>
        </Row>
        <Row>
          <Col>
            <Sidebar />
          </Col>
          <Col md={8}>
            <Tabs />
          </Col>
          <Col></Col>
        </Row>
        <Alert />
        <Modal />
      </Container>
    ) : (
      <Login />
    );
  }
}
