import React from "react";
import NewClient from "./NewClient";
import ClientService from "../ClientService";
import TabService from "../../main/TabService";
import ClientViewContainer from "../view/ClientViewContainer";
import AlertService from "../../alert/AlertService";

export default class NewClientContainer extends React.Component {
  static displayName = "NewClientContainer";

  constructor(props) {
    super(props);

    this.state = {
      countries: [],
      timezones: [],
      client: {
        cname: "",
        url: "",
        email: "",
        country_id: "",
        timezone: "",
      },
      errors: {},
      disableCreate: false,
    };

    this.clientService = new ClientService();

    this.handleChange = this.handleChange.bind(this);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    let client = this.state.client;
    this.clientService.newClientFormData().then((res) => {
      client.country_id = res.data.countries[0].id;
      client.timezone = res.data.timezones[0].value;
      this.setState({
        countries: res.data.countries,
        timezones: res.data.timezones,
        client,
      });
    });
  }

  handleChange(e) {
    let client = this.state.client;
    client[e.target.name] = e.target.value;

    this.setState({
      client: client,
    });
  }

  save() {
    this.setState({
      disableCreate: true,
    });
    this.clientService.create(this.state.client).then(res => {
        TabService.closeCurrentTab();
        TabService.open(
          <ClientViewContainer {...res.data.website} />,
          this.state.client.cname
        );
        AlertService.show(
          "Client " + this.state.client.cname + " was created."
        );
      }).catch(err => {
        if (err.response.data.errors)
          this.setState({
            errors: err.response.data.errors,
            disableCreate: false,
          });

        if (err.response.data.message)
          AlertService.show(err.response.data.message, "red");
    });
  }

  render() {
    return (
      <NewClient
        {...this.state}
        handleChange={this.handleChange}
        save={this.save}
      />
    );
  }
}
