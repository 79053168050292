import React from "react";
import ClientView from "./ClientView";
import ClientService from "../ClientService";

export default class ClientViewContainer extends React.Component {
  static displayName = "ClientViewContainer";

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
    };

    this.clientService = new ClientService();

    this.freeze = this.freeze.bind(this);
    this.updatePlan = this.updatePlan.bind(this);
  }

  componentDidMount() {
    this.clientService.getById(this.props.id).then((res) => {
      this.setState({ ...res.data, loaded: true });
    });
  }

  freeze(freeze) {
    this.clientService.update(this.props.id, { freeze }).then((res) => {
      this.setState({
        freezeSystem: res.data.website.freezeSystem,
      });
    });
  }

  updatePlan(plan) {
    this.setState({ plan: plan.title });
  }

  render() {
    return (
      <ClientView
        {...this.props}
        {...this.state}
        freeze={this.freeze}
        updatePlan={this.updatePlan}
      />
    );
  }
}
