import { Subject } from "rxjs";

const AlertService = {
    showAlert: new Subject(),

    show: function(text, type, noTimeout, icon) {
		if(typeof noTimeout === 'undefined')
			noTimeout = false;

		if(typeof icon === 'undefined')
			icon = false;

		this.showAlert.next({text: text, type: type, noTimeout: noTimeout, icon: icon});
	}
}

export default AlertService;