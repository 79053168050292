import React from "react";
import { Table, Button, Badge } from "react-bootstrap";
import TabService from "../../main/TabService";
import ClientView from "../view/ClientViewContainer";
import ModalService from "../../modal/ModalService";
import "./Clients.scss";

export default class Clients extends React.Component {
  static displayName = "Clients";

  render() {
    return (
      <Table hover size="sm" className="clients-table">
        <thead>
          <tr>
            <th>Client</th>
            <th>URL</th>
            <th>Start Date</th>
            <th>Users</th>
            <th>Upcoming Shifts</th>
            <th>Short Note</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {this.props.clients.map((c, cIndex) => {
            return (
              <tr className={c.status} key={c.id}>
                <td
                  onClick={() =>
                    TabService.open(<ClientView {...c} />, c.client)
                  }
                >
                  {c.client}
                  {c.plan === "free" && (
                    <>
                      <br />
                      <Badge className="bg-primary">Free Trial</Badge>
                    </>
                  )}
                </td>
                <td>
                  {c.domains.map((d) => {
                    return (
                      <React.Fragment key={d.id}>
                        <a
                          href={"https://" + d.fqdn}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {d.fqdn}
                        </a>
                        <br />
                      </React.Fragment>
                    );
                  })}
                </td>
                <td title={c.created_since}>{c.created_at}</td>
                <td>{c.num_users}</td>
                <td>{c.num_shifts}</td>
                <td
                  onClick={() => this.props.addShortNote(cIndex, true)}
                  className="notes"
                >
                  {c.add_short_note === true && (
                    <textarea
                      autoFocus
                      onBlur={() => this.props.addShortNote(cIndex, false)}
                      onKeyUp={(e) => this.props.noteChange(c, e)}
                      maxLength="200"
                    />
                  )}
                  {!c.add_short_note && c.last_short_note?.note}
                </td>
                <td>
                  <Button
                    onClick={() =>
                      ModalService.show(
                        "Delete " + c.client,
                        ["Really delete client ", <b>{c.client}</b>, "?"],
                        () => {
                          this.props.deleteClient(c.id);
                        },
                        null,
                        "Delete"
                      )
                    }
                    size="sm"
                    variant="outline-danger"
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }
}
